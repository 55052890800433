<template>
  <v-container>
    <div id="pitch" class="container">
      <v-row justify="center">
        <h1>Simpel adgang til data fra CVR</h1>
        <p class="ml-4 mr-4 mt-2">
          Vi gør det let, hurtigt og billigt at komme i gang med at integrere CVR data i dit eget
          system!
        </p>
      </v-row>
      <v-row class="mt-10">
        <v-col class="col-lg-4 col-md-4 col-sm-4">
          <h3>Integrer i dag:</h3>
          <ol>
            <li>
              <router-link :to="{ 'name': 'signup' }">
                Opret konto
              </router-link>
            </li>
            <li>Vælg prøveabonnement</li>
            <li> Opret API key </li>
            <li>Integrer med <a rel="noopener noreferrer" target="_blank" :href="docsUrl">vores API</a></li>
          </ol>
        </v-col>
        <v-col class="col-lg-8 col-md-8 col-sm-8">
          <p>
            Få omgående adgang til data fra CVR-registret med et
            <a rel="noopener noreferrer" target="_blank" :href="docsUrl">simpelt og veldokumenteret REST
              API</a>, og undgå ansøgninger, ventetider og integrationer med Virks sparsomt
            dokumenterede Elastic Search endpoints!
          </p>
        </v-col>
      </v-row>
    </div>

    <div id="header-cta" class="container">
      <v-row justify="center">
        <h3 class="pb-3">Kom i gang med det samme</h3>
      </v-row>
      <v-row justify="center">
        <router-link :to="{ 'name': 'signup' }" class="no-decoration">
          <v-btn color="blue">
            Opret konto
          </v-btn>
        </router-link>
      </v-row>
    </div>

    <div id="tour" class="container">
      <v-row justify="center">
        <h3>
          Vi tilbyder
        </h3>
      </v-row>
      <v-row class="tour-row">
        <v-col class="col-sm-7">
          <v-img max-width="100%" max-height="100%" src="/images/online-documentation.png">
          </v-img>
        </v-col>
        <v-col class="col-sm-5">
          <h3>Veldokumenteret API</h3>
          <p>
            Vores <a rel="noopener noreferrer" target="_blank" :href="docsUrl">online dokumentation</a> indeholder alt
            det, du måtte få brug for, når der skal integreres CVR-data ind i
            jeres eget system. Eksempelvis indeholder dokumentationen interaktive eksempler over data, der kan
            returneres fra
            hvert endpoint.
          </p>
        </v-col>
      </v-row>
      <v-row class="tour-row">
        <v-col class="col-sm-7">
          <v-img max-width="100%" max-height="100%" src="/images/raw-data.png">
          </v-img>
        </v-col>
        <v-col class="col-sm-5">
          <h3>Rådata fra CVR</h3>
          <p>
            Vi tilbyder et API med rådata direkte fra Virks CVR-løsning. Rådata sikrer dig, hvis du
            (meget imod forventning) i fremtiden skulle ønske at skifte til deres officielle løsning.
          </p>
          <p>
            Derudover arbejder vi løbende på at tilføje flere endpoints, eksempelvis med
            valideret og afledt data. Hvis du har spørgsmål eller idéer, er du meget velkommen til at skrive til os på
            <a href="mailto:kontakt@cvr.dev">kontakt@cvr.dev</a>
          </p>
        </v-col>
      </v-row>
      <v-row class="tour-row">
        <v-col class="col-sm-7">
          <v-img max-width="100%" max-height="100%" src="/images/ejerskabshierarki.png">
          </v-img>
        </v-col>
        <v-col class="col-sm-5">
          <h3>Ejerskabshierarki</h3>
          <p>
            Vi tilbyder et API med information om ejerskabshierarki, så du med et enkelt kald kan hente information om
            samtlige ejere eller datterselskaber, hele vejen op eller ned i hierarkiet.
          </p>
        </v-col>
      </v-row>
      <v-row class="tour-row">
        <v-col class="col-sm-7">
          <v-img max-width="100%" max-height="100%" src="/images/segmentering.png">
          </v-img>
        </v-col>
        <v-col class="col-sm-5">
          <h3>Segmentering</h3>
          <p>
            Vi tilbyder et pagineret API til segmentering af virksomheder, som eksempelvis kan benyttes til at finde potentielle
            kunder.
          </p>
          <p>
            Filtrer virksomheder på bl.a. branchekode, lokation, ansatte og stiftelsesdato og hent deres
            kontaktoplysninger.
          </p>
        </v-col>
      </v-row>
      <v-row class="tour-row">
        <v-col class="col-sm-7">
          <v-img max-width="100%" max-height="100%" src="/images/postman-documentation.png">
          </v-img>
        </v-col>
        <v-col class="col-sm-5">
          <h3>Postman dokumentation</h3>
          <p>
            Hvis du benytter Postman, kan du med fordel prøve vores <a rel="noopener noreferrer" target="_blank"
              :href="docsUrl + '#section/Introduktion'">Postman-dokumentation</a>.
            Den tillader dig at komme hurtigt igang med at teste vores endpoints, så du kan se om cvr.dev er noget for
            dig!
          </p>
        </v-col>
      </v-row>
      <v-row class="tour-row">
        <v-col class="col-sm-7">
          <v-img max-width="100%" max-height="100%" src="/images/openapi-spec.png">
          </v-img>
        </v-col>
        <v-col class="col-sm-5">
          <h3>OpenAPI spec</h3>
          <p>
            Vi tilbyder en <a rel="noopener noreferrer" target="_blank" :href="docsUrl + '#section/Introduktion'">
              OpenAPI 3.0 specifikation over alle vores endpoints</a>. Den kan
            eksempelvis benyttes til automatisk
            kodegenerering af klienter i jeres foretrukne
            sprog.
          </p>
          <p>
            For at sikre korrektheden af vores specifikation, har vi bygget og benyttet værktøjer til at gennemgå og
            validere specifikationen mod alle datapunkter i CVR.</p>
        </v-col>
      </v-row>
    </div>

    <div id="contact" class="container">
      <v-row justify="center">
        <h2>Spørgsmål og idéer</h2>
        <p>
          Hvis du har spørgsmål eller idéer til specifik funktionalitet, er du mere
          end velkommen til at skrive til os på
          <a href="mailto:kontakt@cvr.dev">kontakt@cvr.dev</a>.
        </p>
      </v-row>
    </div>

    <div id="footer-cta" class="container">
      <v-row justify="center" class="button pb-4">
        <h2>Få omgående adgang til at hente CVR-data</h2>
      </v-row>
      <v-row justify="center">
        <router-link :to="{ 'name': 'signup' }" class="no-decoration">
          <v-btn color="blue">
            Opret konto
          </v-btn>
        </router-link>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import config from "@/config.js";

export default {
  data() {
    return {
      docsUrl: config.DocsUrl,
      apiClientPython: config.APIClientPython,
      apiClientGo: config.APIClientGo,
    };
  },
};
</script>

<style scoped>
.container {
  padding-bottom: 56px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 900px) {
  .container {
    max-width: 900px !important;
  }
}


#tour {
  background-color: white;
}

.tour-row {
  margin-bottom: 56px;
}

.no-decoration {
  text-decoration: none;
}
</style>
